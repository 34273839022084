import { css } from '@emotion/react'
import { isNumber, isUndefined, sum } from 'lodash'
import isFunction from 'lodash/isFunction'
import pWaitFor from 'p-wait-for'
import { memo, useCallback } from 'react'
import { useMount, useUnmount, useUpdateEffect } from 'react-use'
import { apirc } from '~/configs/apirc'
import { remoteSupportSymbols } from '~/configs/remoteSupportSymbols'
import useMedia from '~/hooks/useMedia'
import { useNoSleepMobile } from '~/hooks/useNoSleepMobile'
import { createDatafeedStore } from '~/modules/SDK/Chart2/createDatafeedStore'
import { PineJS } from '~/modules/SDK/Chart2/PineJS'
import { _ChartContainer } from '~/modules/SDK/Chart2/_ChartContainer'
import { _ChartServerSwitch } from '~/modules/SDK/Chart2/_ChartServerSwitch'
import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { eventEmitter } from '~/modules/SDK/Events/eventEmitter'
import { EventString } from '~/modules/SDK/Events/EventString'
import { useMeStore } from '~/modules/SDK/me/useMeStore'
import { sendToSlack } from '~/modules/SDK/slack/sendToSlack'
import { SocketChannel } from '~/modules/SDK/socket/SocketChannel'
import { Socket } from '~/modules/SDK/socket2/Socket'
import { SymbolName } from '~/modules/SDK/Symbol/SymbolName'
import { SymbolString } from '~/modules/SDK/Symbol/SymbolString'
import { symbolStringPrune } from '~/modules/SDK/Symbol/symbolStringPrune'
import {
  ChartingLibraryWidgetConstructor,
  EntityId,
  IChartingLibraryWidget,
  IPositionLineAdapter,
} from '~/public/charting_library_v19/charting_library/charting_library'
import { createStore } from '~/store/createStore'
import dayAPI from '~/utils/dayAPI'
import { meCheckHandlerTrialCodeAndProductWeb } from '../me/meCheckHandlerTrialCodeAndProductWeb'
import { flex } from '~/modules/AppLayout/FlexGridCss'
import { debugAPI } from '~/modules/SDK/debug/debugAPI'
import { UnitTypeLong } from 'dayjs'
import { kbarDefaultStyle } from '~/components/theme/useToggleTvChartTheme'
import { useThemeStore } from '~/components/theme/useThemeStore'
import { fr_instrument } from '~/pages/heineken_template/_fr/fr_instrument'

const DEFAULT_LINE_SHAPE_COLOR = 'purple'

/**
 * # @deprecated chart4 運作良好，此 chart2 棄用
 *
 * 創建一個 useChartStore 的 hook
 *
 * @deprecated
 * @example <caption>Quickly Started</caption>
 *   const useChartOfHyt888 = createChartStore({})
 *
 *   const TestButton: React.FC = props => {
 *     const changeSymbol = useChartOfHyt888(state => state.changeSymbol)
 *
 *     return (
 *       <button
 *         onClick={event => {
 *           changeSymbol('2330')
 *         }}
 *       >
 *         台積電
 *       </button>
 *     )
 *   }
 *
 *   const TestComponent: React.FC = props => {
 *     return (
 *       <div>
 *         <useChartOfHyt888.Symbol />
 *         <TestButton />
 *         <useChartOfHyt888.Chart />
 *       </div>
 *     )
 *   }
 *
 * @example <caption>With Stateful Components for the Chart</caption>
 *   const useChartOfSj = createChartStore({})
 *
 *   const TestComponent: React.FC = props => {
 *     return (
 *       <div>
 *         <useChartOfSj.Symbol />
 *         <useChartOfSj.Chart />
 *         // etc...
 *       </div>
 *     )
 *   }
 *
 * @example <caption>With Hook for the Chart</caption>
 *   const useChartOfSj = createChartStore({})
 *
 *   const TestComponent: React.FC = props => {
 *     return (
 *       <span
 *         onClick={() => {
 *           useChartOfSj.getState().changeSymbol('0050')
 *         }}
 *       >
 *         台灣50（傳家寶）
 *       </span>
 *     )
 *   }
 *
 * @example <caption>With state subscribed of Chart for re-rendering</caption>
 *   const useChartOfSj = createChartStore({})
 *
 *   const TestComponent: React.FC = props => {
 *     const interval = useChartOfSj(state => state.options.interval)
 *
 *     return <span>{interval}</span>
 *   }
 */
export const createChartStore = (options: {
  /**
   * 在背景預裝載這些指標，以利後續加載指標到前端呈現
   *
   * 已列在 defaultsOptions.customIndicators 之指標，不需要再列於此，會將其與此參數合併
   */
  preparedCustomIndicators?: ChartTypes.Indicator[]
  /**
   * 客戶可於介面上選擇的「伺服器之清單」
   *
   * @example <caption>使用自帶的 Preset 設定</caption>
   *   const storeOfOneAgent = createChartStore({
   *     servers: createChartStore.defaultsServers,
   *     // 略
   *   })
   *
   * @example <caption>自訂義伺服器清單</caption>
   *   const serversOfHyt888 = [
   *     {
   *       label: '阿芙蘿黛蒂伺服器',
   *       historyUrl: 'https://tvapi.futures-op.com/v1',
   *       wsUrl: 'https://tvapi.futures-op.com/v1/ws',
   *     },
   *     {
   *       label: '赫菲斯托斯伺服器',
   *       historyUrl: 'https://tvapi.futures-op.com/v2',
   *       wsUrl: 'https://tvapi.futures-op.com/v2/ws',
   *     },
   *   ]
   *
   * @default createChartStore
   */
  servers?: ChartTypes.ServerConfig[]
  /** 當無特別設定 Chart 的 options 時，將預設載入的 options */
  defaultsOptions: Partial<ChartTypes.WidgetOptions>
}) => {
  const createContainerId = () => Math.random().toString(36).slice(2)
  const containerId = createContainerId()

  const useChartStore = createStore<{
    /** 當前作用中的 DOM 的 ID，Chart 應當被渲染在這裡面 */
    containerId: string
    /** 當前作用中的 Symbol 純文字, e.g. `'ES-1'` */
    symbol: Socket.SymbolString
    /** 當前作用中的 interval */
    interval: ChartTypes.WidgetOptions['interval']
    /** 當前作用中的 客製化指標 */
    indicators: ChartTypes.Indicator[]
    /** 最初投入的 options, 即 defaultsOptions */
    initialOptions: Readonly<ChartOptions>
    /** 可選的伺服器清單 */
    servers: ChartTypes.ServerConfig[]
    /** 當前作用中的的伺服器 */
    serverSelected: ChartTypes.ServerConfig
    /** Chart Widget 的 instance */
    widget: null | IChartingLibraryWidget
    /** 改變當前配色 */
    changeColorTheme: (theme?: 'dark' | 'light') => void
    /** 建立一個 Chart Widget 的 instance 並保存在這個 store 裡面 */
    readonly create: (
      options: Partial<
        ChartTypes.WidgetOptions & {
          uid: string
          agentName: string
          agent: string
          datafeedURL: string
          wsURL: string
        }
      >,
    ) => Promise<IChartingLibraryWidget>
    /**
     * 以高度百分比來設定「主圖」或「副圖」的高度
     *
     * 相對於內建式是以高度絕對值px來改變高度，你不再須要自己算高度的絕對值px單位
     *
     * @example
     *   // ## 設定主圖高度70%，副圖高度30%去平均
     *
     *   chart.changePaneRatio(70) // 主圖70,副圖30
     *
     * @example
     *   // ## 設定副圖們高度為主，分別高度為 20%,10%,10%
     *   // 主圖則自動以剩餘的 60% 去填滿（100-20-10-10=60）
     *
     *   chart.changePaneRatio([20, 10, 10]) // 主圖60,副圖20,副圖10,副圖10
     */
    readonly changePaneRatio: (heightRatio: number | number[]) => void
    /** 改變當前作用中的商品代碼 */
    readonly changeSymbol: (symbol?: Socket.SymbolString) => void
    /** 改變當前作用中的指標集群 */
    readonly changeIndicators: (indicators?: ChartTypes.Indicator[]) => Promise<any> | undefined
    /** 新增價格水平線 */
    readonly drawHorizontalPriceLine: (price: number, color?: string) => EntityId | null | undefined
    /** 新增時間垂直線, 時間單位：秒 */
    readonly drawVerticalTimeLine: (unix: number, color?: string) => EntityId | null | undefined
    /** 移除特定entityId圖形 */
    readonly removeEntity: (entityId: EntityId) => void
    datafeed: ReturnType<typeof createDatafeedStore> | null
  }>((set, get) => {
    /**
     * 暫時 demo 用，待實作 API 之後，移除這個 temp
     *
     * @deprecated
     */
    const positionRef: {
      current: IPositionLineAdapter | null
    } = {
      current: null,
    }

    return {
      containerId,
      symbol: options.defaultsOptions.symbol || 'TX-1',
      interval: options.defaultsOptions.interval || 5,
      indicators: options.defaultsOptions.customIndicators || [],
      initialOptions: {
        ...options.defaultsOptions,
        customIndicators: [
          ...(options.defaultsOptions.customIndicators || []),
          ...(options.preparedCustomIndicators || []),
        ],
      },
      servers: options.servers || createChartStore.defaultsServers,
      serverSelected: options.servers?.[0] || createChartStore.defaultsServers[0],
      widget: null,
      datafeed: null,
      changeColorTheme(theme: 'dark' | 'light' = 'dark') {
        try {
          const widget = get().widget

          if (theme === 'dark') {
            widget?.changeTheme('Dark')
          } else {
            widget?.changeTheme('Light')
          }

          /**
           * `changeTheme()` 是 async 的，官方文件上可以 `.then` 但實際 runtime 卻是 return void； 因此 setTimeout
           * 解決，但仍無法解決快速切換 page 時，引發錯誤的問題。
           *
           * 文件
           * https://github.com/cory8249/charting_library/wiki/Widget-Methods#changethemethemename-options
           */
          setTimeout(() => {
            try {
              widget?.applyOverrides({ ...kbarDefaultStyle })
            } catch (error: any) {
              //
            }
          }, 1500)
        } catch (error: any) {
          console.error(error?.message)
        }
      },
      changePaneRatio(heightRatio: number | number[]) {
        const isTargetMainPane = isNumber(heightRatio)

        // 以主圖配置為主
        if (isTargetMainPane) {
          const panesHeightValues = this.widget?.activeChart().getAllPanesHeight() || []

          /** 總體可分配總高 */
          const windowHeight = sum(panesHeightValues)

          /** 主圖被分配之高度 */
          const mainHeight = (windowHeight * heightRatio) / 100

          /** 副圖可分配總高 */
          const panesHeight = windowHeight - mainHeight

          /** 每個副圖的平均高 */
          const paneHeight =
            /** 所以副圖的總高 */
            panesHeight /
            /** 總共有幾個副圖 */
            (panesHeightValues.length - 1)

          // 一口氣配置所有的 pane 高度
          this.widget?.activeChart().setAllPanesHeight(
            panesHeightValues.map((originHeight, paneIndex) => {
              // 主圖
              if (paneIndex === 0) {
                return mainHeight
              }

              // 副圖
              return paneHeight
            }),
          )

          debugAPI.chart2.log(`changePaneRatio(heightRatio = ${heightRatio})`)
        }
        // 以指定副圖配置為主
        else {
          const panesHeightValues = this.widget?.activeChart().getAllPanesHeight() || []

          /** 總體可分配總高 */
          const windowHeight = sum(panesHeightValues)

          /** 主圖要分配的勝餘% */
          const mainHeight = (windowHeight * (100 - sum(heightRatio))) / 100

          // 一口氣配置所有的 pane 高度
          this.widget?.activeChart().setAllPanesHeight([
            mainHeight,
            ...heightRatio.map(ratio => {
              const paneHeight = (windowHeight * ratio) / 100
              return paneHeight
            }),
          ])

          debugAPI.chart2.log(`changePaneRatio(heightRatio = ${heightRatio})`)
        }
      },
      changeSymbol(symbol) {
        symbol &&
          set(state => {
            state.symbol = symbol
          })

        try {
          const chart = get().widget?.chart()

          symbol &&
            chart?.setSymbol(symbol as string, () => {
              'noop'
            })
        } catch (error) {
          // 使 TVChart 不存在時也能切換 symbol
        }
      },
      changeIndicators(indicators) {
        const chart = get().widget?.chart()
        const currentSymbol = symbolStringPrune(chart?.symbol() || get().symbol)

        if (!chart || !currentSymbol) {
          return
        }

        const customFNs = indicators || options.defaultsOptions.customIndicators || []

        set(state => {
          state.indicators = customFNs
        })

        // 轉換 `'1d'` 成為純數字 `1440`
        const intervalNumber =
          {
            '1h': 60,
            '2h': 120,
            '3h': 180,
            '4h': 240,
            '8h': 480,
            '1d': 1440,
          }[String(get().interval).toLowerCase()] || Number(get().interval)

        const fnIDs = customFNs
          .filter(indicatorFN => {
            const hasStudyName = !!indicatorFN?.id || !!indicatorFN.name

            const shouldActive = indicatorFN.enabledOn?.(
              currentSymbol,
              remoteSupportSymbols[currentSymbol],
              SocketChannel,
              intervalNumber,
              fr_instrument.getSymbol(currentSymbol),
            )

            if (shouldActive === false) {
              return false
            }

            if (!hasStudyName) {
              const fnCode = indicatorFN.toString()?.replace(/[\s]*/gi, '').slice(0, 200)

              sendToSlack(
                `TradingViewChart 無法載入指標，因為 FN.name 可能在 ssg 環境發生了不可預期的錯誤（FN「${fnCode}」）`,
              )
            }

            return hasStudyName
          })
          .map(indicatorFN => {
            const fnId = indicatorFN?.id?.toUpperCase() || indicatorFN.name.toUpperCase()

            /**
             * 兼容兩種同名指標，同時被 import 在單支檔案模組時，被 webpack 重新命名成 sg1_sg1 的情況
             *
             * @example
             *   import { sg1 as sgHyt888 } from '~/indicators/hyt888/sg1'
             *   import { sg1 as sgGood178 } from '~/indicators/good178/sg1'
             *
             *   const defaultsConfigs = {
             *     good178: {
             *       get customIndicators() {
             *         return [sgGood178]
             *       },
             *     },
             *     hyt888: {
             *       get customIndicators() {
             *         return [sgHyt888]
             *       },
             *     },
             *   }
             */
            if (!fnId.includes('_')) {
              return fnId
            }

            return fnId.split('_')[0]
          })

        chart.getAllStudies().forEach(study => {
          // 只移除期天所提供的指標，不要影響到客戶所自行添加並儲存為版面的指標
          const names = get().initialOptions.customIndicators?.map(fn =>
            (fn.id || fn.name).toLowerCase(),
          )
          if (names?.includes(study.name.toLowerCase())) {
            chart.removeEntity(study.id)
          }
        })

        // 以下這串，是因為 changeIndicator（aka createStudy）是異步的
        // 他的 promise resolve 時機應該是有bug，
        // 當 `changeIndicator()` 馬上接 `changePaneRatio()`
        // 會拿不到新的 indicator 的 `paneHeight`
        // 因此加上 setTimeout 讓它可以拿到 `paneHeight`
        return new Promise((resolve, reject) => {
          Promise.all(
            fnIDs.map(studyName => {
              return chart.createStudy(studyName, false, false)
            }),
          ).then(entityIds => {
            setTimeout(() => {
              resolve(entityIds)
            }, 10)
          })
        })
      },
      async create(chartOptions) {
        // const { widget: Widget } = await import(
        //   '~/public/charting_library_v19/charting_library/charting_library.esm'
        // )

        /** Async import client TVLib 然後建立 datafeed 和 widget 的 instance */
        const Widget = globalThis.TradingView.widget as unknown as ChartingLibraryWidgetConstructor

        set(state => {
          state.containerId = containerId

          const _options = {
            ...state.initialOptions,
            ...chartOptions,
            containerId,
          }

          if (!_options.agent) {
            throw new Error('錯誤：agent 丟失了')
          }

          if (!_options.uid) {
            throw new Error('錯誤：找不到已登入權仗')
          }

          state.datafeed = createDatafeedStore(
            state.serverSelected || options.servers?.[0] || createChartStore.defaultsServers[0],
            _options.agent,
            chartOptions.agentProduct,
          )

          const widgetOptions: ChartTypes.WidgetOptions = {
            debug: false,
            container: containerId,
            autosize: true,
            symbol: _options.symbol || 'TX-1',
            interval: _options.interval || '5',
            timezone: 'exchange',
            time_frames: [{ text: '1d', resolution: '5' as AnyFIXME, description: '1 Days' }],
            datafeed: state.datafeed.getState().toDatafeed(),
            library_path: '/charting_library_v19/charting_library/',
            locale: 'zh_TW' as AnyFIXME,
            enabled_features: [
              'chart_property_page_trading',
              'hide_last_na_study_output',
              'fix_left_edge',
            ],
            disabled_features: [
              'main_series_scale_menu',
              'header_fullscreen_button',
              'header_screenshot',
              'header_fullscreen_button',
              //'legend_widget',
              ...(_options.isMobile ? ['left_toolbar'] : []),
              ...(_options.enableVolumeIndicator ? [] : ['create_volume_indicator_by_default']),
              ...(_options.disabledSymbolSearch ? ['header_symbol_search'] : []),
              ...(_options.disabledLeftToolbar ? ['left_toolbar'] : []),
              ...(_options.disabledGoToDate ? ['go_to_date'] : []),
              ...(_options.disabledLegendMenu ? ['legend_context_menu'] : []),
              ...(_options.disabledScalesMenu ? ['scales_context_menu'] : []),
              ...(_options.disabledPaneMenu ? ['pane_context_menu'] : []),
              ...(_options.disabledHeaderWidget ? ['header_widget'] : []),
              ...(_options.disabledHeaderInterval ? ['header_resolutions'] : []),
              ...(_options.disabledHeaderChartType ? ['header_chart_type'] : []),
              ...(_options.disabledHeaderCompare ? ['header_compare'] : []),
              ...(_options.disabledHeaderSaveload ? ['header_saveload'] : []),
              ...(_options.disabledTimeframesToolbar ? ['timeframes_toolbar'] : []),
              ...(_options.disableHeaderChartType ? ['header_chart_type'] : []),
              ...(_options.disableHeaderIndicators ? ['header_indicators'] : []),
              ...(_options.disableHeaderUndoRedo ? ['header_undo_redo'] : []),
            ],
            theme: _options.darkmode ? 'Dark' : 'Light',
            charts_storage_url: apirc.chartsStorageUrl.baseUrl,
            /** The backend API has modified by the backend team, and it's naming to by custom as 1.2 */
            charts_storage_api_version: '1.2',
            /** 用來保存 end-user 儲存圖表的參數。不能隨意改動此參數，不然 end-user 他們存的圖表會遺失 */
            client_id: _options.client_id || '_AGENT_NON_SET_',
            user_id: _options.uid,
            overrides: {
              /** 保持空，交給 applyOverrides */
            },
            studies_overrides: {
              'volume.volume.color.0': '#6ba583',
              'volume.volume.color.1': '#d75442',
              'moving average.precision': 0,
            },
            custom_indicators_getter: (pineJS: PineJS.This) => {
              return Promise.resolve(
                [...(get().initialOptions.customIndicators || [])].map(indicator => {
                  const PineInstance = indicator(pineJS)

                  return PineInstance
                }),
              )
            },
          }

          debugAPI.chart2.log(`Chart2.create(`, widgetOptions, `)`)

          state.widget = new Widget(widgetOptions as AnyFIXME) as IChartingLibraryWidget
        })

        const widget = get().widget

        if (!widget) {
          throw new Error('錯誤：Chart Widget 編譯錯誤')
        }

        widget.onChartReady(() => {
          widget.applyOverrides({
            /** 基本預設的樣式複寫，主要改為紅色漲，綠色跌 */
            ...{
              'mainSeriesProperties.candleStyle.upColor': '#d75442',
              'mainSeriesProperties.candleStyle.downColor': '#6ba583',
              'mainSeriesProperties.candleStyle.borderUpColor': '#5b1a13',
              'mainSeriesProperties.candleStyle.borderDownColor': '#225437',
              'mainSeriesProperties.candleStyle.wickUpColor': 'rgba( 115, 115, 117, 1)',
              'mainSeriesProperties.candleStyle.wickDownColor': 'rgba( 115, 115, 117, 1)',
              'mainSeriesProperties.candleStyle.drawBorder': false,
              'paneProperties.topMargin': 15,
              'paneProperties.bottomMargin': 25,
              'timeScale.rightOffset': 20,
            },
            ...options.defaultsOptions.overrides,
          })
          this.changeIndicators(options.defaultsOptions.customIndicators)

          const chart = widget.chart()
          const datafeed = get().datafeed

          if (!chart || !datafeed) return

          datafeed.getState().connect()

          const symbolBefore = symbolStringPrune(chart.symbol() || '')
          const intervalBefore = get().interval

          eventEmitter.emit(EventString.onTVChartSymbolChanged, {
            chartContainerID: get().containerId,
            beforeValue: symbolBefore,
            afterValue: symbolBefore,
          })

          eventEmitter.emit(EventString.onTVChartIntervalChanged, {
            chartContainerID: get().containerId,
            beforeValue: intervalBefore,
            afterValue: intervalBefore,
          })

          chart.onIntervalChanged().subscribe(null, (newIntervalValue: any) => {
            set(state => {
              const originInterval = state.interval
              state.interval = newIntervalValue

              eventEmitter.emit(EventString.onTVChartIntervalChanged, {
                chartContainerID: state.containerId,
                beforeValue: originInterval,
                afterValue: newIntervalValue,
              })
            })
            get().changeIndicators(get().indicators)
          })

          chart.onSymbolChanged().subscribe(null, () => {
            //先 Set商品 後 指標會以剛剛Set的商品 根據國內外商品顯示出來(若指標有分 tw、os)
            set(state => {
              const symbolCurrent = symbolStringPrune(chart.symbol() || '')

              state.symbol = symbolCurrent

              eventEmitter.emit(EventString.onTVChartSymbolChanged, {
                chartContainerID: state.containerId,
                beforeValue: symbolBefore,
                afterValue: symbolCurrent,
              })
            })
            get().changeIndicators(get().indicators)
          })
        })

        // 等待到 Iframe 裡面的 Chart 整個被 render 好的時候
        pWaitFor(
          () => {
            const iframeChartInner = !!globalThis.document.querySelector<HTMLIFrameElement>(
              `[id="${get().containerId}"] iframe`,
            )?.contentWindow?.document.body?.innerHTML

            return iframeChartInner
          },
          { interval: 500, timeout: 15000 },
        ).then(() => {
          /** Chart Element in the Iframe that has been rendered */
          const element = globalThis.document.querySelector<HTMLIFrameElement>(
            `[id="${get().containerId}"] iframe`,
          )

          if (element) {
            eventEmitter.emit(EventString.onTVChartReady, {
              elementId: get().containerId,
              element,
            })

            // 當 Chart 這一個分頁，重新被 user 瀏覽時，重新觸發 datafeed 的 getBars 將掉線的 K 棒補回去
            // FIXME: TODO: 這會造成切換分頁時，user移除的指標，被重置的問題
            // eventEmitter.on(EventString.visibilityChange, data => {
            //   if (data.state === 'visible') {
            //     widget.chart().resetData()
            //   }
            // })
          }
        })

        return widget
      },
      drawHorizontalPriceLine(price: number, color?: string) {
        const customColor = color ?? DEFAULT_LINE_SHAPE_COLOR
        const activeChart = get().widget?.activeChart()
        const result = activeChart?.createShape(
          {
            time: dayAPI().unix() * 1000,
            price: price,
          },
          {
            shape: 'horizontal_line',
            overrides: { linecolor: customColor },
            // lock: true,
            // disableSelection: true,
            // disableSave: true,
            // disableUndo: true,
          },
        )

        return result
      },
      drawVerticalTimeLine(unix: number, color?: string) {
        const customColor = color ?? DEFAULT_LINE_SHAPE_COLOR
        const activeChart = get().widget?.activeChart()
        const result = activeChart?.createShape(
          {
            time: unix,
            price: 0,
          },
          {
            shape: 'vertical_line',
            overrides: { linecolor: customColor },
            // lock: true,
            // disableSelection: true,
            // disableSave: true,
            // disableUndo: true,
          },
        )

        return result
      },
      removeEntity(entity: EntityId) {
        const activeChart = get().widget?.activeChart()
        activeChart?.removeEntity(entity)
      },
    }
  })

  /**
   * 當前作用中的 Symbol 對應顯示文字
   *
   * @example <caption>僅僅顯示當前作用中的 Symbol</caption>
   *   return (
   *     <Button
   *       variant='contained'
   *       color='primary'
   *     >
   *       您正在收看 🟢 <hyt888Chart.SymbolDisplayText />
   *     </Button>
   *   )
   */
  function Symbol() {
    const currentSymbol = useChartStore(state => state.symbol)

    return <SymbolName symbol={currentSymbol} />
  }

  function SymbolString_() {
    const currentSymbol = useChartStore(state => state.symbol)

    return <SymbolString symbol={currentSymbol} />
  }

  /**
   * 改變當前作用中的 Symbol 文字
   *
   * @example <caption>使用 onClick 切換作用中的 Symbol</caption>
   *   return (
   *     <Fragment>
   *       <p>⭐️ 你還可以選擇轉台</p>
   *       <div>
   *         <useChartOfHog.SymbolSwitch
   *           symbol='0050'
   *           render={symbol => <Button variant='outlined'>切換到 {symbol}</Button>}
   *         />
   *       </div>
   *       <useChartOfHog.Chart />
   *     </Fragment>
   *   )
   */
  function SymbolSwitch(
    props: ReactProps<{
      render?(symbol: React.ReactNode): React.ReactNode
      symbol?: Socket.SymbolString
    }>,
  ) {
    return (
      <span
        className={props.className}
        css={css`
          display: inline-block;
          width: 100%;
        `}
        onClick={event => {
          if (props.symbol) {
            useChartStore.getState().changeSymbol(props.symbol)
          }
        }}
      >
        {isFunction(props.render) ? (
          props.render(<SymbolName symbol={props.symbol} />)
        ) : (
          <SymbolName symbol={props.symbol} />
        )}
      </span>
    )
  }

  /**
   * @example <caption>onClick 後載入指定客製化指標</caption>
   *   // 建立 ChartStore 時，必須先在 preparedCustomIndicators 或 defaultsOptions.customIndicators 準備好你的客製化指標
   *   const useChartOfHog = createChartStore({
   *     preparedCustomIndicators: [myOsFuturesIndicator, myTwFuturesIndicator],
   *     defaultsOptions: {
   *       customIndicators: [bb1, hma1, sg1, smi1],
   *     },
   *   })
   *
   *   // 隨後使用 <useChartOfHog.IndicatorsSwitch /> 包住你的按紐
   *   const MyComponent = () => (
   *     <useChartOfHog.IndicatorsSwitch indicators={[myOsFuturesIndicator]}>
   *       <Button variant='outlined'>載入海期指標</Button>
   *     </useChartOfHog.IndicatorsSwitch>
   *   )
   */
  function IndicatorsSwitch(
    props: ReactProps<{
      indicators: ChartTypes.Indicator[]
    }>,
  ) {
    return (
      <span
        className={props.className}
        css={css`
          display: inline-block;
          width: 100%;
        `}
        onClick={event => {
          try {
            useChartStore.getState().changeIndicators(props.indicators)
          } catch (error: AnyExplicit) {
            if (error?.message?.includes('unexpected study')) {
              throw new Error(
                `錯誤：要載入的指標，並沒有在 prepareCustomIndicators 的時候預裝載進來`,
              )
            } else {
              throw new Error(
                `錯誤：載入指標時發生不明錯誤（${
                  error?.message
                }）載入失敗的指標：${props.indicators.map(fn => fn.id)}`,
              )
            }
          }
        }}
      >
        {props.children}
      </span>
    )
  }

  function SymbolIndicatorsSwitch(
    props: ReactProps<{
      symbol: Socket.SymbolString
      indicators: ChartTypes.Indicator[]
    }> &
      ReactPropsRequiredRender<{ symbolDisplayText: React.ReactNode }>,
  ) {
    return (
      <SymbolSwitch
        symbol={props.symbol}
        render={symbolDisplayText => {
          return (
            <IndicatorsSwitch
              className={props.className}
              indicators={props.indicators}
            >
              {props.render({ symbolDisplayText })}
            </IndicatorsSwitch>
          )
        }}
      />
    )
  }

  /**
   * 用來切換當前 TVChart 作用中的伺服器
   *
   * @example <caption>在前端介面顯示一個下拉式選單，讓客戶可以選擇他要的伺服器</caption>
   *   const MyComponent = () => {
   *     return (
   *       <div>
   *         <p>⭐️ 換一個伺服器看看吧</p>
   *         <hyt888Chart.ServerSwitch />
   *       </div>
   *     )
   *   }
   */
  function ServerSwitch(props: ReactProps) {
    const currentServer = useChartStore(state => state.serverSelected)
    const servers = useChartStore.getState().servers

    return (
      <_ChartServerSwitch
        className={props.className}
        currentServer={currentServer}
        servers={servers}
        onChange={server => {
          useChartStore.setState({ serverSelected: server })
        }}
      />
    )
  }

  function Chart(
    props: ReactProps<{
      darkmode?: boolean
      noFullscreenButton?: boolean
    }>,
  ) {
    const meUid = useMeStore(state => state.meUserState?.uid)
    const validatedUser = meCheckHandlerTrialCodeAndProductWeb.useCheck()
    const isMobile = useMedia().isPhone
    const chart = useChartStore.getState()
    const agentConfig = useMeStore(state => state.agentConfig)
    const agentName = useMeStore(state => state.agentName)
    const agentProduct = useMeStore(state => state.product)
    const server = useChartStore(state => state.serverSelected)
    const isPagesDark = useThemeStore(state => state.theme) === 'dark'
    useNoSleepMobile()

    const initialize = useCallback(() => {
      if (
        agentConfig.name &&
        (meUid || validatedUser) &&
        chart.containerId &&
        globalThis.document.querySelector(`[id="${chart.containerId}"]`)
      ) {
        chart.create({
          uid: meUid ?? 'trial-user',
          client_id: agentName,
          agent: agentName,
          agentProduct: agentProduct,
          darkmode: props.darkmode ?? (isPagesDark || agentConfig.theme?.palette.mode === 'dark'),
          isMobile,
          datafeedURL: server.historyUrl,
          wsURL: server.wsUrl,
        })
      }
    }, [
      agentConfig.name,
      agentConfig.theme?.palette.mode,
      agentName,
      agentProduct,
      chart,
      isMobile,
      isPagesDark,
      meUid,
      props.darkmode,
      server.historyUrl,
      server.wsUrl,
      validatedUser,
    ])

    useMount(() => {
      initialize()
    })

    useUnmount(() => {
      chart.widget?.remove()
      chart.datafeed?.getState().disconnect()
    })

    useUpdateEffect(() => {
      if (chart.widget) return

      initialize()
    }, [meUid, server.label, chart.containerId, validatedUser])

    useUpdateEffect(() => {
      if (!chart.widget) return
      chart.widget?.remove()
      chart.datafeed?.getState().disconnect()
      initialize()
    }, [server, meUid, validatedUser])

    if (!validatedUser && !meUid) {
      return (
        <div
          css={css`
            ${flex.h.default};
            place-content: center;
            height: 100%;
            width: 100%;
            place-items: center;
          `}
        >
          尚未登入或沒有權限
        </div>
      )
    }

    return (
      <_ChartContainer
        data-testid='charting_library_v19'
        noFullscreenButton={props.noFullscreenButton}
        className={props.className}
        containerId={chart.containerId}
      />
    )
  }

  /**
   * @example <caption>追蹤並重新渲染 TVChart 的屬性</caption>
   *   const TestComponent: React.FC = props => {
   *     const symbol = useChartStore(state => state.options.symbol)
   *
   *     return <div>你的 TVChart 當前的 Symbol 為 {symbol}</div>
   *   }
   *
   * @example <caption>客製自己的 TVChart 功能</caption>
   *   const TestComponent: React.FC = props => {
   *     const changeSymbol = useChartStore(state => state.options.changeSymbol)
   *
   *     return (
   *       <button
   *         onClick={() => {
   *           changeSymbol('0050')
   *         }}
   *       >
   *         點我便立即把 0050 買起來當傳家寶！
   *       </button>
   *     )
   *   }
   */
  function useStore(...args: Parameters<typeof useChartStore>): ReturnType<typeof useChartStore> {
    return useChartStore(...args) as typeof useChartStore
  }

  useStore.Chart = memo(Chart)
  useStore.IndicatorsSwitch = memo(IndicatorsSwitch)
  useStore.ServerSwitch = memo(ServerSwitch)
  useStore.Symbol = memo(Symbol)
  useStore.SymbolString = memo(SymbolString_)
  useStore.SymbolSwitch = memo(SymbolSwitch)
  useStore.SymbolIndicatorsSwitch = memo(SymbolIndicatorsSwitch)
  useStore.getState = useChartStore.getState
  useStore.setState = useChartStore.setState
  useStore.subscribe = useChartStore.subscribe
  useStore.destroy = useChartStore.destroy

  return useStore as unknown as typeof useChartStore & {
    Chart: typeof Chart
    IndicatorsSwitch: typeof IndicatorsSwitch
    /** @deprecated 現在有新版 `<ChartServerSelect />` 組件可用，差異為 CSS 客製化模式不一樣 */
    ServerSwitch: typeof ServerSwitch
    SymbolSwitch: typeof SymbolSwitch
    SymbolIndicatorsSwitch: typeof SymbolIndicatorsSwitch
    Symbol: typeof Symbol
    SymbolString: typeof SymbolString_
    getState: (typeof useChartStore)['getState']
    setState: (typeof useChartStore)['setState']
    subscribe: (typeof useChartStore)['subscribe']
    destroy: (typeof useChartStore)['destroy']
  }
}

createChartStore.defaultsServers = [...apirc.chartServer]

type ChartOptions = Partial<ChartTypes.WidgetOptions & { symbol: Socket.SymbolString }>
