import { AnyObject } from 'immer/dist/internal'
import { MutableRefObject } from 'react'
import io from 'socket.io-client'
import { LiteralUnion } from 'type-fest'
import urlcat from 'urlcat'
import { createChartDatafeedOfPolling } from '~/modules/SDK/chart4/createChartDatafeedOfPolling'
import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { debugAPI } from '~/modules/SDK/debug/debugAPI'
import { Socket } from '~/modules/SDK/socket2/Socket'
import { socketOnSleepRecoverReconnect } from '~/modules/SDK/Socket3/socketOnSleepRecoverReconnect'
import {
  HistoryMetadata,
  IBasicDataFeed,
  IDatafeedQuotesApi,
  IExternalDatafeed,
} from '~/public/charting_library_v19/charting_library/charting_library'
import { createStore } from '~/store/createStore'
import { useMeStore } from '~/modules/SDK/me/useMeStore'
import dayAPI from '~/utils/dayAPI'
import { fr_agents } from '~/pages/heineken_template/_fr/fr_agents'
import { cloneDeep, isUndefined } from 'lodash'
import { AnyFunction } from 'tsdef'
import { Dayjs } from 'dayjs'
import { fr_datafeedStatus } from '~/pages/heineken_template/_fr/fr_datafeedStatus'

// 這兩組物件，如果搬進去 factory 裡面的話，在「chart4」會發生「切換伺服器時，K棒不會跳動」的問題
const subTopics: {
  [guid: string]: ChartTypes.DatafeedSymbolTopic
} = {}
const historyLastKbarTime = {} as AnyObject

enum SocketAction {
  subscribeSymbol = 'subscribe-symbol',
  unsubscribeSymbol = 'unsubscribe-symbol',
}

// TODO: 你可以先研究一下 現在 kbar 到底怎麼載入 怎麼 update 中間有出現斷線 或是 相關的邏輯，不希望 component 整個 re-render
// TODO: 大目標就是  確保各種情況  chart 都能正常  不要斷線/重載/漏K棒
/**
 * # @deprecated datafeed2 運作上線中，此 datafeed1 即將棄用
 *
 * 管理 socket 連線邏輯，並提供給 TVChart SDK
 *
 * @deprecated
 */
export const createDatafeedStore = (
  server: ChartTypes.ServerConfig,
  /**
   * # 因為是常數，未來直接內部用 agentStore 即可，不必再傳值
   *
   * @deprecated
   */
  agent = fr_agents.agent,
  /**
   * # 因為是常數，未來直接內部用 agentStore 即可，不必再傳值
   *
   * @deprecated
   */
  agentProduct = fr_agents.product,
) =>
  createStore<{
    server: ChartTypes.ServerConfig
    toDatafeed(): ChartTypes.DatafeedAPI
    /** 如果採用舊式 Polling 時，則不會提供 socket instance */
    socket: Socket.Client | null
    /** 當採用 Socket Datafeed 時，使用這個 function 連線 */
    connect(): void
    /** 當採用 Socket Datafeed 時，使用這個 function 斷線 */
    disconnect(): void
  }>((set, get) => {
    const product = agentProduct.replace(/[\s\S]+?@/i, '')

    const $http = {
      get(url: string, params: AnyObject = {}) {
        return globalThis
          .fetch(
            urlcat(get().server.historyUrl + url, {
              ...params,
              agent,
              product,
            }),
          )
          .then(res => res.json())
          .then(data => ({ data }))
      },
    }

    const socket = io(server.wsUrl || '', {
      query: {
        uid: useMeStore.getState().meUserState?.uid,
        agent,
        product,
      },
      autoConnect: false,
      reconnection: true, // 休眠/斷網後，前端應能自動重連。 TODO: 檢查 enable 後是否有其他可能的 bug
    })

    // logs
    socket.emit = new Proxy(socket.emit, {
      apply: (target, ev, args: [SocketAction, ...any]) => {
        log(`socket.emit(${args[0]})`, args[1], { subTopics })
        Reflect.apply(target, ev, args)
      },
    })

    const socketRef: MutableRefObject<Socket.Client | null> = {
      current: socket,
    }

    socket.on('connect', () => {
      log('socket.on(connect)', { subTopics })
      for (const key in subTopics) {
        const { symbol, resolution, lastBarTime, subscriberUID } = subTopics[key]
        socket.emit(SocketAction.subscribeSymbol, {
          symbol,
          resolution,
          lastBarTime,
          subscriberUID,
        })
      }
    })

    socket.on('disconnect', (event: AnyFIXME) => {
      log('socket.on(disconnect)', event)
    })

    socket.on('error', (error: AnyFIXME) => {
      log('socket.on(error)', error)
    })

    socket.on('data', (event: { data: AnyFIXME; subscriberUID: AnyFIXME }) => {
      const { data, subscriberUID } = event
      let newBars = JSON.parse(data) as ChartTypes.DatafeedBar[]
      const sub = subTopics[subscriberUID]

      log('socket.on(data)', { newBars, subTopics })
      fr_datafeedStatus.analyzeKBars(newBars)

      if (!sub) return
      if (newBars.length > 0) {
        // 若尚未有lastBar時間資訊(僅在第一次收到資料觸發)
        if (!sub.lastBarTime)
          sub.lastBarTime = historyLastKbarTime[subscriberUID] ?? newBars[0].time

        // 濾掉時間比lastBar還早的K棒
        newBars = newBars.filter((b: AnyFIXME) => {
          if (isUndefined(sub.lastBarTime)) return false
          return b['time'] >= sub.lastBarTime
        })
        // 畫上(更新)K棒
        newBars.forEach((bar: AnyFIXME, index: AnyFIXME) => {
          sub.lastBarTime = bar.time
          sub.listener(bar)
        })
      }
    })

    socketOnSleepRecoverReconnect(socketRef)

    return {
      server: {
        historyUrl: server.historyUrl,
        label: server.label,
        wsUrl: server.wsUrl,
      },
      socket: server.wsUrl ? socket : null,
      connect: () => {
        log(`datafeed.connect()`)
        socket.connect()
        socketRef.current = socket
      },
      disconnect: () => {
        log(`datafeed.destroy()`)
        socketRef.current = null
        socket.disconnect()
      },
      toDatafeed(updateFrequency = 5000) {
        if (!server.wsUrl) {
          return createChartDatafeedOfPolling(agent, get().server.historyUrl, updateFrequency)
        }

        return {
          onReady: callback => {
            type Data = {
              /** E.g. `['1', '3', '5', '15', '30', '60', '1D', 'W']` */
              supported_resolutions: string[]
              supports_group_request: boolean
              supports_marks: boolean
              supports_search: boolean
              supports_time: boolean
              supports_timescale_marks: boolean
              /**
               * E.g.
               *
               * - 0: {name: "全部商品", value: "all_types"}
               * - 1: {name: "國內期貨", value: "futures"}
               * - 2: {name: "海外期貨", value: "os_futures"}
               * - 3: {name: "指數", value: "index"}
               * - 4: {name: "選擇權", value: "option"}
               * - 5: {name: "股票", value: "stock"}
               */
              symbols_types: { name: string; value: string }[]
              /** E.g. `'Asia/Taipei'` */
              timezone: string
            }

            $http.get('/config', {}).then((response: { data: Data }) => {
              log(`chart.onReady()`, response.data)
              return callback({
                ...response.data,
              } as AnyFIXME)
            })
          },
          searchSymbols: (userInput, exchange, symbolType, onResultReadyCallback) => {
            const params: Partial<{
              limit: LiteralUnion<30, number>
              /**
               * 傳入要被搜尋的字串
               *
               * E.g. `NQ`
               */
              query: string
              exchange: string
              type: LiteralUnion<'os_futures', string>
            }> = {
              limit: 30,
              query: userInput,
              exchange,
              type: symbolType,
            }

            $http.get('/search', params).then(
              (response: {
                data: {
                  description: string
                  exchange: string
                  full_name: string
                  symbol: string
                  type: string
                }[]
              }) => {
                onResultReadyCallback(response.data as AnyFIXME)
              },
            )
          },
          resolveSymbol: (symbolName, onSymbolResolvedCallback, onResolveErrorCallback) => {
            $http
              .get('/symbols', { symbol: symbolName })
              .then(resp => {
                const data: ParametersHead<typeof onSymbolResolvedCallback> = {
                  ...resp.data,
                }

                onSymbolResolvedCallback(data)
              })
              .catch(resp => {
                onResolveErrorCallback(resp)
              })
          },
          getBars: (symbolInfo, resolution, periodParams, onResult, onError) => {
            const isFirstCall = periodParams.firstDataRequest
            const params = {
              symbol: symbolInfo.ticker,
              resolution,
              from: periodParams.from,
              to: periodParams.to,
              countBack: periodParams.countBack,
              ws: true,
              metaData: true,
            }
            $http
              .get('/history', params)
              .then(async resp => {
                const metaData: HistoryMetadata = {
                  noData: resp.data.metaData.noData,
                  nextTime: resp.data.metaData.nextTime,
                }
                let historyKbars = resp.data.bars

                // 第一次呼叫若noData, retry一次
                if (isFirstCall && metaData.noData) {
                  const retryResp: AnyFIXME = await $http.get('/history', params)
                  metaData.noData = retryResp.data.metaData.noData
                  metaData.nextTime = retryResp.data.metaData.nextTime
                  historyKbars = retryResp.data.bars
                }

                // 記錄history回傳的最後一根K棒時間, 作為後續ws推播K棒參考
                if (isFirstCall && historyKbars.length > 0) {
                  const subscriberUID = `${params.symbol}_#_${params.resolution}`
                  historyLastKbarTime[subscriberUID] = historyKbars[historyKbars.length - 1].time
                }

                onResult(historyKbars, metaData)
                return true
              })
              .catch((error: DatafeedHttpError) => {
                let reason = error.message
                if (error.response) {
                  reason = error.response.data ? error.response.data.error.errCode : reason
                }
                onError(reason)
                return false
              })
          },
          subscribeBars(
            symbolInfo,
            resolution,
            onRealtimeCallback,
            subscriberUID,
            onResetCacheNeededCallback,
          ) {
            // 使休眠斷線後的 Chart 重新取得資料用（不確定有無真實效果）
            // https://github.com/cory8249/charting_library/wiki/Chart-Methods#resetdata
            // onResetCacheNeededCallback()
            log(`chart.subscribeBars()`, { subTopics })
            const sub = subTopics[subscriberUID]
            if (sub) return

            const symbol = symbolInfo.ticker
            socket.emit(SocketAction.subscribeSymbol, { symbol, resolution, subscriberUID })

            if (!symbol) {
              console.warn(`找不到 symbol`, { symbolInfo })
            }

            subTopics[subscriberUID] = {
              symbol,
              subscriberUID,
              resolution,
              symbolInfo,
              lastBarTime: undefined,
              listener: onRealtimeCallback,
            }
          },
          unsubscribeBars(subscriberUID) {
            const sub = subTopics[subscriberUID]
            if (!sub) return
            if (historyLastKbarTime[subscriberUID]) delete historyLastKbarTime[subscriberUID]
            socket.emit(SocketAction.unsubscribeSymbol, { subscriberUID })
            delete subTopics[subscriberUID]
          },
          getServerTime(callback) {
            $http.get('/time').then(resp => {
              callback(resp.data)
            })
          },
        }
      },
    }
  })

export type DatafeedAPI = IBasicDataFeed | (IBasicDataFeed & IDatafeedQuotesApi & IExternalDatafeed)
export type DatafeedHttpError = {
  message: string
  response: { data: { error: { errCode: AnyFIXME } } }
}

const log = debugAPI.datafeed.log.bind(debugAPI.datafeed)
