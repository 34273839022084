import { css } from '@emotion/react'
import { MenuItem, Select } from '@mui/material'
import { memo } from 'react'
import { flex } from '~/modules/AppLayout/FlexGridCss'
import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'

/** @deprecated 現在有新版 `<ChartServerSelect />` 組件可用，差異為 CSS 客製化模式不一樣 */
export const _ChartServerSwitch = memo<
  ReactProps<{
    currentServer: ChartTypes.ServerConfig
    servers: ChartTypes.ServerConfig[]
    onChange?(selectedServer: ChartTypes.ServerConfig): void
  }>
>(function _ChartServerSwitch(props) {
  return (
    <span
      className={props.className}
      //若無設定寬高 則Select-ui會跑版(原生樣式)
      css={css`
        #__next & {
          ${flex.h.default};
          box-sizing: border-box;
        }
      `}
    >
      <Select
        size='small'
        data-testid={testids.select}
        value={props.currentServer?.historyUrl || ''}
        renderValue={value => {
          return props.currentServer.label
        }}
        onChange={event => {
          const url = event.target.value
          const server = props.servers.find(_server => _server.historyUrl === url)

          if (server) {
            props.onChange?.(server)
          }
        }}
        //若物件設定寬高為100% 則會依賴外層寬高
        css={css`
          width: 100%;
          height: 100%;
        `}
      >
        {props.servers.map((server, index) => {
          return (
            <MenuItem
              key={index}
              value={server.historyUrl}
            >
              {server.label}
            </MenuItem>
          )
        })}
      </Select>
    </span>
  )
})

export const testids = {
  select: 'select',
}
