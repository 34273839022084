import { css } from '@emotion/react'
import React, { memo } from 'react'
import { WithFullscreenButton } from '~/components/WithFullscreenButton'
import { Fragment } from 'react'
import { flex } from '~/modules/AppLayout/FlexGridCss'

/** @private */
export const _ChartContainer = memo<
  ReactProps<{
    containerId?: string
    noFullscreenButton?: boolean
  }>
>(function Chart(props) {
  if (!props.containerId) {
    return <span>containerId 生成錯誤</span>
  }

  const content = (
    <div
      id={props.containerId}
      css={css`
        ${flex.h.default};
        width: 100%;
        height: 100%;
      `}
      className={`${props.noFullscreenButton ? 'charting_library' : ''} ${props.className}`}
    />
  )

  return (
    <Fragment>
      {!props.noFullscreenButton ? (
        <WithFullscreenButton className={`charting_library`}>{content}</WithFullscreenButton>
      ) : (
        content
      )}
    </Fragment>
  )
})

// 這行是為了給 next/dynamic 使用
export default _ChartContainer
