import { useEffect, useState } from 'react'
import { useUnmount } from 'react-use'
import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { createChartStore } from '~/modules/SDK/Chart2/createChartStore'
import { ChartingModule } from '~/modules/SDK/chart4/ChartingModule'
import { useThemeStore } from './useThemeStore'

export const kbarDefaultStyle = {
  'mainSeriesProperties.candleStyle.upColor': '#d75442',
  'mainSeriesProperties.candleStyle.downColor': '#6ba583',
  'mainSeriesProperties.candleStyle.borderUpColor': '#5b1a13',
  'mainSeriesProperties.candleStyle.borderDownColor': '#225437',
  'mainSeriesProperties.candleStyle.wickUpColor': 'rgba( 115, 115, 117, 1)',
  'mainSeriesProperties.candleStyle.wickDownColor': 'rgba( 115, 115, 117, 1)',
}
type Override = ChartTypes.WidgetOptionOverrideStyle

const useToggleTvChartTheme = (
  chartInstance?: ReturnType<typeof createChartStore> | ChartingModule | null,
) => {
  const widget =
    chartInstance instanceof ChartingModule
      ? chartInstance.widget
      : chartInstance?.getState().widget

  const destroyChart =
    chartInstance instanceof ChartingModule
      ? () => {
          return chartInstance.destroy()
        }
      : () => {
          chartInstance?.getState().widget?.remove()
          chartInstance?.getState().datafeed?.getState().disconnect()
        }

  const theme = useThemeStore(s => s.theme)

  const [isChartReady, setIsChartReady] = useState<boolean>(false)
  const [shouldChangeStyle, setShouldChangeStyle] = useState<boolean>(false)

  const [lightOverride, setLightOverride] = useState<Override | null>(null)
  const [darkOverride, setDarkOverride] = useState<Override | null>(null)

  const overrideStyle = theme === 'dark' ? darkOverride : lightOverride

  useEffect(() => {
    const timerId = setTimeout(() => {
      setIsChartReady(true)
    }, 3000)

    return () => {
      clearTimeout(timerId)
    }
  }, [isChartReady, widget])

  useEffect(() => {
    if (isChartReady && widget) {
      widget?.applyOverrides({ ...overrideStyle })
      const t = theme === 'dark' ? 'Dark' : 'Light'
      widget?.changeTheme(t)

      setTimeout(() => {
        widget?.applyOverrides({ ...overrideStyle })
      }, 100)

      setShouldChangeStyle(true)
    }
  }, [isChartReady, widget, theme, overrideStyle])

  useEffect(() => {
    // 避免與上面 changeTheme 同步，稍稍延遲，可以試著拿掉 shouldChangeStyle 這個 flag
    if (isChartReady && widget && shouldChangeStyle) {
      widget?.applyOverrides({})
      widget?.applyOverrides({ ...overrideStyle })
      setShouldChangeStyle(false)
    }
  }, [isChartReady, widget, theme, overrideStyle, shouldChangeStyle])

  useUnmount(() => {
    setIsChartReady(false)
    if (chartInstance) {
      destroyChart()
    }
  })

  return {
    state: { isChartReady },
    acts: {
      setLightOverride,
      setDarkOverride,
    },
  }
}

export default useToggleTvChartTheme
