import find from 'lodash/find'
import { Fragment, memo, useMemo } from 'react'
import { remoteSupportSymbols } from '~/configs/remoteSupportSymbols'
import { Socket } from '~/modules/SDK/socket2/Socket'

/** 顯示 Symbol 裸碼 */
export const SymbolString = memo<
  ReactProps<
    | { symbol: string }
    | { symbol: Socket.SymbolString }
    | { symbol: typeof remoteSupportSymbols[keyof typeof remoteSupportSymbols]['description'] }
  >
>(function SymbolString(props) {
  const _symbolStringBySymbolString =
    remoteSupportSymbols[props.symbol as Socket.SymbolString]?.symbol

  const _symbolStringBySymbolName = useMemo(() => {
    if (_symbolStringBySymbolString) return _symbolStringBySymbolString
    return find(remoteSupportSymbols, info => info.description === props.symbol)?.symbol
  }, [props.symbol, _symbolStringBySymbolString])

  return (
    <Fragment>{_symbolStringBySymbolString || _symbolStringBySymbolName || props.symbol}</Fragment>
  )
})
